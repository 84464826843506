.videoIframeContainer {
  padding: 6rem 5% 3rem;
  width: 100%;
  margin: 0 auto;

  box-sizing: border-box;
  background-color: #111111;
}
.videoIframeContainer iframe {
  display: block;
}

.videosThumbsContainer {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 1rem 5%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f9f9f9;
}

.videosThumbsContainer .thumb {
  box-sizing: border-box;
  padding: 0.5rem;
  margin: 1rem;
  max-width: 260px;
  cursor: pointer;
  text-align: left;
  background-color: #f0f0f0;
  font-size: 0.8rem;
}

.videosThumbsContainer .thumb:hover {
  background-color: #ffffff;
}

.videosThumbsContainer .thumb img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .videoIframeContainer {
    padding-top: 56px;
    padding-bottom: 0;
  }
}
