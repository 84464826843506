.imageAndTextElem img {
  width: 100%;
  display: block;
}

.imageAndTextElem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.imageAndTextElem a {
  display: block;
}

.imageAndTextElem .text > div {
  max-width: 640px;
  padding: 1rem;
}

@media (max-width: 640px) {
  .imageAndTextElem .text > div {
    max-width: 100%;
    width: 100%;
    padding: 1rem;
  }
  .imageAndTextElem {
    font-size: 1rem;
    line-height: 1.6rem;
  }
}
