body {
  color: #000;
}
.header header {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: none;
}
.loading {
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  z-index: 1;
  width: 100%;
  /* background-image: url("/assets/logoload.svg"); */
  background-size: 52px 52px;
  background-repeat: no-repeat;
  background-position: center 49%;
}

.loading .MuiCircularProgress-root {
  width: 80px !important;
  height: 80px !important;
}

.headerLogo {
  height: 48px;
}

body,
.MuiMenuItem-root,
.MuiTypography-root,
.MuiButton-label,
strong,
b {
  font-family: "Montserrat", sans-serif;
}
.MuiButton-label,
h1,
h2,
h3 {
  /* font-family: "Abril Fatface", cursive; */
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: initial;
  text-transform: uppercase;
}

p {
  margin: 0;
  padding-bottom: 1rem;
}

p,
ul {
  margin: 0 !important;
  padding: 0 0 1rem 0;
}
ul {
  padding-left: 1.2rem;
}
ul li {
  padding-bottom: 0.4rem;
}

a {
  color: inherit;
}

.showsmall {
  display: none;
}

.divider {
  height: 56px;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
  border: solid 1px #f4f4f4 !important;
}

@media (min-width: 600px) {
  .divider {
    height: 64px;
  }
}

.simplemenu {
  display: inline-block;
}

.MuiDrawer-paper {
  min-width: 260px;
}

.MuiButton-root {
  border-radius: 0 !important;
}

.lowest {
  background-color: #000;

  padding: 1rem;
  text-align: center;
  text-decoration: none;
  padding-bottom: 6rem;
}

.langBtn.selected {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .showsmall {
    display: initial;
  }
  .hidesmall {
    display: none;
  }
  .headerLogo {
    height: 42px;
  }
  .bgWrapper {
    background-attachment: scroll !important;
  }
}

@media (max-width: 640px) {
  .headerLogo {
    height: 38px;
  }
}

.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.swipe-wrap {
  overflow: hidden;
  position: relative;
}
.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
}

.loading > div {
  margin: 0 auto;
}

.snip-layout {
  z-index: 2000;
}

.btn {
  color: rgba(255, 255, 255, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 500;
  line-height: 1.75;
  border-radius: 0;
  text-transform: uppercase;
  background-color: #ff1886;
  text-decoration: none;
}

.socialheader {
  display: inline-block;
}

.socialheader a {
  display: inline-block;
  padding: 0 0.4rem;
}

.socialheader a img {
  display: inline-block;
  width: 32px;
  vertical-align: middle;
}

.playlists {
  text-align: center;
}

.playlists .playlist {
  display: inline-block;
  padding: 0.5rem;
}
