.articleInner {
  /* background-color: #f1f1f1;
  padding-bottom: 6rem; */
}

.articleInner .articleImage {
  /* width: 100%;
  height: auto; */
}

.articleInner .topbg {
  height: 20vh;
  background-size: 110%;
  background-position: center;
}

.articleInner .articleContent {
  max-width: 1024px;
  padding: 6rem 10% 6rem 10%;
  margin: -2rem auto 0 auto;
  position: relative;
  z-index: 5;
  background-color: #fff;
}
.articleInner .date {
  color: #ccc;
  padding-bottom: 2rem;
}

.articleInner .articleContent p {
  /* padding: 0 0 1rem 0;
  box-sizing: border-box; */
}

.article #share {
  box-sizing: border-box;
  text-decoration: none;
  padding: 1rem;
  margin-top: 1rem;
  display: block;
  text-align: center;
  max-width: 250px;
  width: 100%;
  margin: 4rem auto 0 auto;
  border: solid 1px;
}

.article #share > * {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}

.article .shareButtons {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article .shareButtonsInner {
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  background-color: #fff;
  width: 100%;
  max-width: 460px;
}

.article .shareButtonsInner .SocialMediaShareButton {
  display: inline-block;
  margin: 0.2rem;
}

.article .shareButtonsInner .SocialMediaShareButton svg {
  width: 100% !important;
  height: auto !important;
  margin: 0;
}

@media (max-width: 1024px) {
  .articleInner .articleContent {
    margin: -2rem 2rem 2rem;
    padding: 2rem 2rem 6rem 2rem;
  }
}

@media (max-width: 640px) {
  .articleInner .articleContent p {
    /* padding: 0 0 1rem 0; */
  }
}
