h1 {
  font-size: 4.8rem;
  padding: 0.4em 0;
  margin: 0;
  line-height: 1;
}

h2 {
  font-size: 2.2rem;
  padding: 0.4em 0;
  margin: 0;
  line-height: 1;
}

h3 {
  font-size: 1.6rem;
  padding: 0.4em 0;
  margin: 0;
  line-height: 1;
}

h4 {
  font-size: 1.2rem;
  padding: 0.4em 0;
  margin: 0;
  line-height: 1;
}

h5 {
  font-size: 1rem;
  padding: 0.4em 0;
  margin: 0;
  line-height: 1;
}

h6 {
  font-size: 0.82rem;
  padding: 0.4em 0;
  margin: 0;
  line-height: 1;
}

@media (max-width: 640px) {
  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1rem;
  }
}

.textcenter {
  text-align: center;
}
.textright {
  text-align: right;
}
.textleft {
  text-align: left;
}

.thegrid * {
  box-sizing: border-box;
}

.thegrid.padding,
.thecell.padding {
  padding: 1em;
}

.thegrid {
  position: relative;
  box-sizing: border-box;
}

.thegrid::after {
  content: "";
  clear: both;
  display: block;
}
.thecell {
  float: left;
  width: 100%;
  position: relative;
  min-height: 1px;
  box-sizing: border-box;
}

.x100 {
  width: 100%;
}
.x95 {
  width: 95%;
}
.x90 {
  width: 90%;
}
.x85 {
  width: 85%;
}
.x80 {
  width: 80%;
}
.x75 {
  width: 75%;
}
.x70 {
  width: 70%;
}
.x66 {
  width: 66.666%;
}
.x65 {
  width: 65%;
}
.x60 {
  width: 60%;
}
.x55 {
  width: 55%;
}
.x50 {
  width: 50%;
}
.x45 {
  width: 45%;
}
.x40 {
  width: 40%;
}
.x35 {
  width: 35%;
}
.x33 {
  width: 33.333%;
}
.x30 {
  width: 30%;
}
.x25 {
  width: 25%;
}
.x20 {
  width: 20%;
}
.x16 {
  width: 16.666%;
}
.x15 {
  width: 15%;
}
.x10 {
  width: 10%;
}
.x5 {
  width: 5%;
}

.uniform > .x50:nth-of-type(2n + 1) {
  clear: both;
}
.uniform > .x33:nth-of-type(3n + 1) {
  clear: both;
}
.uniform > .x25:nth-of-type(4n + 1) {
  clear: both;
}
.uniform > .x20:nth-of-type(5n + 1) {
  clear: both;
}
.uniform > .x16:nth-of-type(6n + 1) {
  clear: both;
}
.uniform > .x10:nth-of-type(10n + 1) {
  clear: both;
}
.uniform > .x5:nth-of-type(20n + 1) {
  clear: both;
}

@media (max-width: 1024px) {
  .s-textcenter {
    text-align: center;
  }
  .s-textright {
    text-align: right;
  }
  .s-textleft {
    text-align: left;
  }
  .s-center {
    margin: 0 auto;
    float: none !important;
  }
  .s-left {
    float: left !important;
  }
  .s-right {
    float: right !important;
  }

  .uniform > .x50:nth-of-type(2n + 1) {
    clear: none;
  }
  .uniform > .x33:nth-of-type(3n + 1) {
    clear: none;
  }
  .uniform > .x25:nth-of-type(4n + 1) {
    clear: none;
  }
  .uniform > .x20:nth-of-type(5n + 1) {
    clear: none;
  }
  .uniform > .x16:nth-of-type(6n + 1) {
    clear: none;
  }
  .uniform > .x10:nth-of-type(10n + 1) {
    clear: none;
  }
  .uniform > .x5:nth-of-type(20n + 1) {
    clear: none;
  }

  .s-x100 {
    width: 100% !important;
  }
  .s-x95 {
    width: 95% !important;
  }
  .s-x90 {
    width: 90% !important;
  }
  .s-x85 {
    width: 85% !important;
  }
  .s-x80 {
    width: 80% !important;
  }
  .s-x75 {
    width: 75% !important;
  }
  .s-x70 {
    width: 70% !important;
  }
  .s-x66 {
    width: 66.666% !important;
  }
  .s-x65 {
    width: 65% !important;
  }
  .s-x60 {
    width: 60% !important;
  }
  .s-x55 {
    width: 55% !important;
  }
  .s-x50 {
    width: 50% !important;
  }
  .s-x45 {
    width: 45% !important;
  }
  .s-x40 {
    width: 40% !important;
  }
  .s-x35 {
    width: 35% !important;
  }
  .s-x33 {
    width: 33.333% !important;
  }
  .s-x30 {
    width: 30% !important;
  }
  .s-x25 {
    width: 25% !important;
  }
  .s-x20 {
    width: 20% !important;
  }
  .s-x16 {
    width: 16.666% !important;
  }
  .s-x15 {
    width: 15% !important;
  }
  .s-x10 {
    width: 10% !important;
  }
  .s-x5 {
    width: 5% !important;
  }

  .uniform > .s-x50:nth-of-type(2n + 1) {
    clear: both;
  }
  .uniform > .s-x33:nth-of-type(3n + 1) {
    clear: both;
  }
  .uniform > .s-x25:nth-of-type(4n + 1) {
    clear: both;
  }
  .uniform > .s-x20:nth-of-type(5n + 1) {
    clear: both;
  }
  .uniform > .s-x16:nth-of-type(6n + 1) {
    clear: both;
  }
  .uniform > .s-x10:nth-of-type(10n + 1) {
    clear: both;
  }
  .uniform > .s-x5:nth-of-type(20n + 1) {
    clear: both;
  }
}

@media (max-width: 640px) {
  .xs-textcenter {
    text-align: center;
  }
  .xs-textright {
    text-align: right;
  }
  .xs-textleft {
    text-align: left;
  }
  .xs-center {
    margin: 0 auto;
    float: none !important;
  }
  .xs-left {
    float: left !important;
  }
  .xs-right {
    float: right !important;
  }

  .uniform > .s-x50:nth-of-type(2n + 1) {
    clear: none;
  }
  .uniform > .s-x33:nth-of-type(3n + 1) {
    clear: none;
  }
  .uniform > .s-x25:nth-of-type(4n + 1) {
    clear: none;
  }
  .uniform > .s-x20:nth-of-type(5n + 1) {
    clear: none;
  }
  .uniform > .s-x16:nth-of-type(6n + 1) {
    clear: none;
  }
  .uniform > .s-x10:nth-of-type(10n + 1) {
    clear: none;
  }
  .uniform > .s-x5:nth-of-type(20n + 1) {
    clear: none;
  }

  .xs-x100 {
    width: 100% !important;
  }
  .xs-x95 {
    width: 95% !important;
  }
  .xs-x90 {
    width: 90% !important;
  }
  .xs-x85 {
    width: 85% !important;
  }
  .xs-x80 {
    width: 80% !important;
  }
  .xs-x75 {
    width: 75% !important;
  }
  .xs-x70 {
    width: 70% !important;
  }
  .xs-x66 {
    width: 66.666% !important;
  }
  .xs-x65 {
    width: 65% !important;
  }
  .xs-x60 {
    width: 60% !important;
  }
  .xs-x55 {
    width: 55% !important;
  }
  .xs-x50 {
    width: 50% !important;
  }
  .xs-x45 {
    width: 45% !important;
  }
  .xs-x40 {
    width: 40% !important;
  }
  .xs-x35 {
    width: 35% !important;
  }
  .xs-x33 {
    width: 33.333% !important;
  }
  .xs-x30 {
    width: 30% !important;
  }
  .xs-x25 {
    width: 25% !important;
  }
  .xs-x20 {
    width: 20% !important;
  }
  .xs-x16 {
    width: 16.666% !important;
  }
  .xs-x15 {
    width: 15% !important;
  }
  .xs-x10 {
    width: 10% !important;
  }
  .xs-x5 {
    width: 5% !important;
  }

  .uniform > .xs-x50:nth-of-type(2n + 1) {
    clear: both;
  }
  .uniform > .xs-x33:nth-of-type(3n + 1) {
    clear: both;
  }
  .uniform > .xs-x25:nth-of-type(4n + 1) {
    clear: both;
  }
  .uniform > .xs-x20:nth-of-type(5n + 1) {
    clear: both;
  }
  .uniform > .xs-x16:nth-of-type(6n + 1) {
    clear: both;
  }
  .uniform > .xs-x10:nth-of-type(10n + 1) {
    clear: both;
  }
  .uniform > .xs-x5:nth-of-type(20n + 1) {
    clear: both;
  }
}

.center {
  float: none;
  margin: 0 auto;
  overflow: hidden;
}
