.tabcontent {
  max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.MuiTabs-scrollButtonsDesktop {
  display: inline-flex !important;
}

.MuiTabs-flexContainer {
  justify-content: center;
}

@media (max-width: 1024px) {
  .MuiTabs-flexContainer {
    justify-content: initial;
  }
}
