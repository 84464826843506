.infos {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.infos .info {
  width: 288px;
  padding: 1rem;
}

.infos .info a {
  text-decoration: none;
}

.infos .info a:hover {
  text-decoration: underline;
}
