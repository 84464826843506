.react-parallax img {
  display: block;
  line-height: 0;
}

.slick-slide > div > div {
  display: block !important;
}

.sliderBg {
  transition: background-color 0.6s;
  position: relative;
}

.sliderLink {
  text-decoration: none;
}

.sliderBgInner {
  display: table;
  height: 101%;
  width: 100%;
  /* text-align: center; */
  max-width: 1280px;
  margin: 0 auto;
}
.sliderBgInner .centered {
  display: table-cell;
  color: #fff;
  vertical-align: middle;
  padding: 0rem 4rem;
}

.sliderBgInner .centered img {
  max-height: 320px;
  /* max-height: 40%; */
  width: auto;
  max-width: 100%;
  /* margin: 0 auto; */
}

.sliderBgInner .centered > * {
  display: inline-block;
  vertical-align: middle;
}

.sliderText {
  padding: 1rem;
}

/* .sliderText h1 {
  font-size: 8rem;
  line-height: 1em;
} */

.sliderCTA {
  padding: 1rem !important;
  min-width: 160px !important;
  font-style: normal;
  background-color: transparent !important;
  border-radius: 0 !important;
  border: solid !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.swiper-button-prev {
  left: 0;
  padding: 0 2rem;
}
.swiper-button-next {
  right: 0;
  padding: 0 2rem;
}

.swiper-button-prev:focus,
.swiper-button-next:focus {
  outline: none;
}
@media (max-width: 1024px) {
  .sliderText h1 {
    font-size: 4.6rem;
  }
  .swiper-button-prev {
    left: 0;
    padding: 0 1rem;
  }
  .swiper-button-next {
    right: 0;
    padding: 0 1rem;
  }
  .sliderBgInner .centered img {
    max-height: 260px;
  }
  /* .sliderBgInner .centered {
    font-size: 0.6em;
  } */
}
@media (max-height: 640px) {
  /* .swiper-button-prev {
    left: 0;
    padding: 0 1rem;
  }
  .swiper-button-next {
    right: 0;
    padding: 0 1rem;
  } */
  /* .sliderBgInner .centered img {
    max-height: 260px;
  } */
}

@media (max-width: 640px) {
  .sliderText h1 {
    font-size: 3.2rem;
  }
  .sliderBgInner .centered {
    text-align: center;
  }
}
