.privacyPopUp {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
  font-size: 0.8rem;
  width: 100%;
  box-sizing: border-box;
  z-index: 777;
}

.privacyPopUpInner .buttons {
  display: inline-block;
}

.privacyPopUpInner .text {
  display: inline-block;
}

.privacyPopUpInner .buttons > * {
  display: inline-block;
  margin: 1rem;
}
