.mapContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  position: relative;
  min-height: 50vh;
}

.mapContent {
  padding: 2rem 10% !important;
  display: flex;
  align-items: center;
}

.mapContent a {
  text-decoration: none;
}

.mapContent a:hover {
  text-decoration: underline;
}

.leaflet-control,
.leaflet-container {
  z-index: 1;
}

@media (max-width: 640px) {
  .mapContent {
    padding: 2rem !important;
  }
}
