.articlesFeedContainer {
  text-align: center;
  padding: 2rem 1rem;
}

.articlesFeedContainer .articleFeedCard {
  width: 260px;
  display: inline-block;
  margin: 1rem;
  text-align: left;
  vertical-align: top;
}

.articlesFeedContainer .MuiPaper-elevation1 {
  box-shadow: none;
  background-color: #fff;
}

.articleFeedCard .bigLink {
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

.articleFeedCard .articleFeedCardActions {
  justify-content: flex-end;
}

.articlesFeedContainerInner {
  max-width: 1280px;
  margin: 0 auto;
}

.articlesFeedContainer .articleFeedCard.first {
  width: 555px;
  position: relative;
}

.articlesFeedContainer .articleFeedCard.first .MuiCardMedia-root {
  height: 236px !important;
}

.articlesFeedContainer .articleFeedCard.first .MuiCardContent-root {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

@media (max-width: 1280px) {
  .articlesFeedContainer .articleFeedCard.first {
    width: 260px;
  }
  .articlesFeedContainer .articleFeedCard {
    width: 260px;
  }

  .articlesFeedContainer .articleFeedCard.first .MuiCardMedia-root {
    height: 160px !important;
  }

  .articlesFeedContainer .articleFeedCard.first .MuiCardContent-root {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    color: initial;
  }
}

.presaveband {
  position: absolute;
  z-index: 1;
  background-color: #ff1886;
  color: #fff;
  padding: 1rem;
  transform: rotate(-45deg);
  width: 300px;
  text-align: center;
  left: -120px;
  top: 40px;
}
