.releaseInner #spotifyIframe {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 1;
}

.releaseInner #spotifyIframe iframe {
  height: 80px;
}

.releaseInner #spotifyIframe.open iframe {
  height: 50vh;
}

.releaseInner .topbg {
  height: 20vh;
  background-size: 110%;
  background-position: center;
}

.releaseInner .maxwidth {
  max-width: 1206px;
  margin: 0 auto;
  padding: 0 0 4rem 0;
  box-sizing: border-box;
}

.releaseInner .releaseImageContainer {
  display: inline-block;
  width: 30%;
  box-sizing: border-box;
  vertical-align: top;
  padding: 2rem;
  /* position: sticky; */
  /* top: 6rem; */
}

.releaseInner .releaseImageContainer img {
  display: block;
  width: 100%;
  height: auto;
}

.releaseInner .releaseContentContainer {
  display: inline-block;
  width: 70%;
  box-sizing: border-box;
  vertical-align: top;
  padding: 2rem;
  background-color: transparent;
  margin-top: 0rem;
  z-index: 1;
  position: relative;
}

.releaseInner .releaseContentContainer h2 {
  font-size: 1.6rem;
  padding: 0;
  padding-bottom: 1rem;
}

.releaseInner .releaseContentContainer h1 {
  font-size: 2.6rem;
  padding: 0;
  padding-bottom: 1rem;
}

.releaseInner .date {
  padding-bottom: 1rem;
  opacity: 0.6;
}

.releaseInner .links .link {
  display: block;
  padding: 0.5rem;
  margin-top: 0.2rem;
  box-sizing: border-box;
  border: solid 1px;
  text-align: center;
}

.releaseInner .links .link:hover {
  background-color: #fff;
}

.releaseInner .links .presave {
  background-color: #ff1886;
  color: #fff;
  border-color: #ff1886;
}

.releaseInner .links .presave:hover {
  background-color: #ffffff;
  color: #ff1886;
  border-color: #ff1886;
}

.links {
  margin-top: 1rem;
}

.links .link img {
  display: inline-block;
  width: 100px;
}

.release #share,
.release #presave {
  text-decoration: none;
  padding: 1rem;
  margin-top: 1rem;
}

.release #share > *,
.release #presave > * {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}

.release .shareButtons {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.release .shareButtonsInner {
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  background-color: #fff;
  width: 100%;
  max-width: 450px;
}

.release .shareButtonsInner .SocialMediaShareButton {
  display: inline-block;
  margin: 0.2rem;
}

.release .shareButtonsInner .SocialMediaShareButton svg {
  width: 100% !important;
  height: auto !important;
  margin: 0;
}

@media (max-width: 1024px) {
  .releaseInner .releaseImageContainer {
    width: 50%;
  }
  .releaseInner .releaseContentContainer {
    width: 50%;
  }
}

@media (max-width: 640px) {
  .releaseInner .releaseImageContainer {
    width: 100%;
  }
  .releaseInner .releaseContentContainer {
    width: 100%;
  }

  .releaseInner .maxwidth {
    margin-top: -13vh;
    position: relative;
  }
}

.presaveband {
  position: absolute;
  z-index: 1;
  background-color: #ff1886;
  color: #fff;
  padding: 1rem;
  transform: rotate(-45deg);
  width: 300px;
  text-align: center;
  left: -120px;
  top: 40px;
}
