.simplemenulist {
  display: inline-block;
}

.simplemenulist .hl {
  background-color: rgb(210, 106, 106);
}

.simpleMenuListContent {
  position: absolute;
  margin-top: 14px;
  margin-left: -10px;
}
